import React from "react";
import Layout from "../Components/layout";
import Loginc from "../Components/loginc";
import { Seo } from "../Components/seo";


export default function Logout() {
  return (
    <Layout>
      <Loginc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)
import React, { useEffect, useState } from "react";
import useCurrentWidth from "./useCurrentWidth";
import { setCookie } from "../Utils/Cookies";
import { Fetcher } from "../Utils/AxiosWrapper";

export default function Loginc() {
  useEffect(() => {
    var input = document.getElementById("password");

    // Execute a function when the user releases a key on the keyboard
    if(input){input.addEventListener("keyup", function(event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("login").click();
      }
    });}
    input = document.getElementById("email")
    if(input){input.addEventListener("keyup", function(event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("login").click();
      }
    });}
  },[])
  const width = useCurrentWidth();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [error, setError] = useState("");
  const forgotPassword = e => {
    //it triggers by pressing the enter key
  if (e.key === "Enter") {
    window.location.href="/resetPassword"
  }
};
const register = e => {
  //it triggers by pressing the enter key
if (e.key === "Enter") {
  window.location.href="/register"
}
};
  return (
      <>
      <div style={{color: `black`, minHeight: `100vh`, display: `flex`}}>
        <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}} onClick={() => {window.location.replace("/")}}>
          <img style={{padding: (width < 700) ? `0px 6vw` : `7vw`, width: `48vw`, height: (width < 700) ? `40vw` : `50vw`}} 
        src={(width < 700) ? "/Images/ReviewHeart.png" : "/Images/LoginLeft.png"}></img></div>
        <div style={{width: `5px`, minHeight: `100vh`, backgroundColor: `#e37100`}}/>
        <div style={{width: `49vw`}}><div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}}>
          <img src="/Images/BottomLogo.png" style={{width: (width < 700) ? `41vw` : `20vw`, height: (width < 700) ? `28vw` : `14vw`, marginTop: `20vh`}}></img></div>
        <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: (width < 375) ? `45vh` : `50vh`}}>
          <div style={{color: `black`, fontSize: (width < 700) ? `12px` : `16px`, fontWeight: `800`}}>
          <div>Email</div>
          <div className="login" style={{width: (width < 700) ? `41vw` : `28vw`}}>
          <input  type="text" id="email" name="email"/> 
          <div style={{marginTop: `20px`}}>Password</div>
          <input  type="password" id="password" name="password"/> 
          <div tabIndex="0" style={{float: `right`, fontSize: `13px`, cursor: `pointer`, marginTop: `10px`}}
          onClick={() => {window.location.href="/resetpassword"}}
          onKeyPress={forgotPassword}
          >Forgot Password</div>
          <div style={{marginTop: `10px`, color: `red`}}>{error}</div>
          <button id="login" style={{ cursor: `pointer`, marginTop: `50px`, textTransform: `uppercase`}}
                  onClick={() => { 
                    Fetcher("POST", "/api/Login", {Email: document.getElementById("email").value, password: document.getElementById("password").value})
                    .then(({data}) => {
                      setCookie("token", data.token, 1);
                      if (urlParams.get("cart"))
                      {
                        window.location.href = "/cart";
                        return;
                      }
                      if (urlParams.get("page"))
                      {
                        window.location.href = urlParams.get("page");
                        return;
                      }
                      if (urlParams.get("id"))
                      {
                        window.location.href = "/itemdetail"+window.location.search;
                        return;
                      }
                      else{
                        window.location.href = "/";
                        return;
                      }
                      
                    })
                    .catch((err) => {
                        if (err.response && err.response.data && err.response.data.Message)
                        {
                          setError(err.response.data.Message);
                        }
                        else{
                          setError("error: "+JSON.stringify(err));
                        }
                    });
                }}>Log in</button>
          <div  tabIndex="0" style={{marginTop:`10px`, fontSize: `13px`, float: `right`, cursor: `pointer`, }}
          onClick={() => {window.location.href="/register"}} onKeyPress={register}>Register</div>
          </div>
          </div>
          </div>
        </div>
      </div>
      </>
  )
}
